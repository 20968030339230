<template>
  <Navbar  :user="user" />
  <section class="section pt-100 ">
    <div class="container mt-lg-3">
      <div class="row justify-content-center">
        <div class="col-lg-8 col-12">
          <div class="card border-0 rounded shadow">
            <div class="card-body text-center">
              <h4 class="text-center">Change Password :</h4>
                <div class="alert alert-success" v-if="success">
                  <p class="">{{ success }}</p>
                </div>
                <div v-if="errors.old_password">
                </div>
                <div  v-else-if="errors.new_password">
                </div>
                <div  v-else-if="errors.confirm_password">
                </div>
                <div class="alert alert-danger text-center" v-else-if="errors">
                  {{ errors }}
                </div>
              <form @submit.prevent="changePassword">
                <div class="row justify-content-center mt-4">
                  <div class="col-lg-8">
                    <div class="mb-3 text-start">
                      <label class="form-label">Old password :</label>
                      <div class="form-icon position-relative">
                        <input type="password" class="form-control ps-5" v-model="form.old_password"/>
                        <div v-if="errors.old_password" class="text-small text-danger text-start">{{ errors.old_password[0] }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-8">
                    <div class="mb-3 text-start">
                      <label class="form-label">New password :</label>
                      <div class="form-icon position-relative">
                        <input type="password" class="form-control ps-5" v-model="form.new_password"/>
                        <div v-if="errors.new_password" class="text-small text-danger text-start">{{ errors.new_password[0] }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-8">
                    <div class="mb-3 text-start">
                      <label class="form-label">Re-type New password :</label>
                      <div class="form-icon position-relative">
                        <input type="password" class="form-control ps-5" v-model="form.confirm_password"/>
                        <div v-if="errors.confirm_password" class="text-small text-danger text-start">{{ errors.confirm_password[0] }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-8 mt-2 mb-0">
                    <button type="submit" class="btn default-btn" disabled v-if="submitting">
                    <span class="label">{{ value }}</span>
                  </button>
                    <button type="submit" id="submit" class="btn default-btn" v-else>
                      Save password
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios'
import Navbar from "@/components/Navbar.vue";
export default {
  components: { Navbar },
    data(){
    return {
      currentUser: {},
      user: {},
      token: localStorage.getItem('user_data'),
      isLoading: true,
      submitting: false,
      form:{
        old_password: '',
        password: '',
        password_confirmation: ''
      },
      success: false,
      errors: false,
    }
  },
  methods: {
    changePassword(){
      this.submitting = true
      this.value = 'Please Wait...'
      axios.post('https://apitraining.vipawaworks.com/api/auth/user/change_password',this.form).then(response =>{
        response.data
        this.$router.push({ name: 'TrainerProfile'})
      }).catch((errors) => {
          this.errors = errors.response.data.errors
          this.submitting = false
      })
    },
    getUser(){
      axios.get('https://apitraining.vipawaworks.com/api/user').then(response => {
        this.user = response.data
      }).catch(errors => {
        if (errors.response.status === 401) {
          localStorage.removeItem('user_data')
          this.$router.push({ name: 'Login'})
        }
      })
    },
  },
  created(){
    document.title = `Change Password - Training`
    axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}` 
    this.getUser()

  }
};
</script>

<style>
</style>